import React, { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import firebase from '@firebase/app';

const db = firebase.firestore();

const NAME_DB_CLIENTS = "clients"
//const NAME_DB_PHILOFILS = "philofils"
//const NAME_DB_LITTOFILS = "littofils"
const NAME_DB_ABONNEMENTS = 'abonnements'
const NAME_DB_YEARS = 'years'
const NAME_DB_INVOICES = 'invoices_'
const NAME_DB_CREDITS = 'credits'
const NAME_DB_CYCLES = 'cycles'
const NAME_DB_CONFERENCES = 'conferences'
const NAME_DB_SPEAKERS = 'speakers'

const APP_PREFIX_PATH = ''
export const AppViews = () => {
  const [allYears, setAllYears] = useState([]);
  const [abonnements, setAllAbonnements] = useState([]);
  const [clients, setAllClients] = useState([]);
  const [data, setData] = useState([]);
  const [actual_season, setActualSeason] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [conferences, setConferences] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [credits, setCredits] = useState([]);



  // Définition de toutes les pages
  const Component_Dashboard = lazy(() => import(`./dashboard`))
  const Component_Settings = lazy(() => import(`./settings`))
  const Component_Abonnements = lazy(() => import(`./abonnements`))

  const Component_New_Clients = lazy(() => import(`./clientsNew`))
  const Component_Contacts = lazy(() => import(`./contacts`))
  const Component_Clients = lazy(() => import(`./clients`))
  const Component_Edit_Client = lazy(() => import(`./clientEdit`))
  const Component_Add_Client = lazy(() => import(`./clientAdd`))
  const Component_View_Client = lazy(() => import(`./clientView`))

  const Component_Cycles = lazy(() => import(`./cycles`))
  const Component_View_Cycle = lazy(() => import(`./cycleView`))
  const Component_Add_Cycle = lazy(() => import(`./cycleAdd`))
  const Component_Edit_Cycle = lazy(() => import(`./cycleEdit`))

  const Component_Confs = lazy(() => import(`./conferences`))
  const Component_View_Conf = lazy(() => import(`./confView`))
  const Component_Edit_Conf = lazy(() => import(`./confEdit`))

  const Component_Speakers = lazy(() => import(`./speakers`))
  const Component_Edit_Speaker = lazy(() => import(`./speakerEdit`))
  const Component_Add_Speaker = lazy(() => import(`./speakerAdd`))
  const Component_View_Speaker = lazy(() => import(`./speakerView`))

  const Component_Invoices = lazy(() => import(`./invoices`))
  const Component_Credits = lazy(() => import(`./credits`))
  const Component_View_Invoice = lazy(() => import(`./invoiceView`))
  //const Component_Edit_Invoice = lazy(() => import(`./clientAdd`))

  const Component_Emailing = lazy(() => import(`./emailing`))
  const Component_Accounting = lazy(() => import(`./accounting`))
  const Component_Stats = lazy(() => import(`./stats`))


  const mounted = async () => { 
    const allClients = await db.collection(NAME_DB_CLIENTS).get()
		const allAbonnements = await db.collection(NAME_DB_ABONNEMENTS).get()
		const allYears = await db.collection(NAME_DB_YEARS).get()
    const allInvoices = await db.collection(NAME_DB_INVOICES).get()
    const allCredits = await db.collection(NAME_DB_CREDITS).get()

		const allCycles = await db.collection(NAME_DB_CYCLES).get()
		const allConferences = await db.collection(NAME_DB_CONFERENCES).get()
    const allSpeakers = await db.collection(NAME_DB_SPEAKERS).get()

		var arrYears = Array.from(allYears.docs.map((e)=>{
			var objYear = e.data()
			return objYear
		}))
    const actual_season_ = arrYears.filter(year => year.active)[0].year

    var arrAbos = Array.from(allAbonnements.docs.map((e)=>{
			var objClient = e.data()
			objClient.id = e.id
			return objClient
		}))

    var arrConf = Array.from(allConferences.docs.map((e)=>{
			var objConf = e.data()
			objConf.id=e.id
			return objConf
		})).filter(conf => conf.year === actual_season_)

    var arrCycles = Array.from(allCycles.docs.map((e)=>{
			var objCycle = e.data()
			objCycle.id=e.id
			return objCycle
		})).filter(cycle => cycle.year === actual_season_)

    var arrSpeakers = Array.from(allSpeakers.docs.map((e)=>{
			var objSpeaker = e.data()
			objSpeaker.id=e.id
			return objSpeaker
		})).filter(conf => conf.year === actual_season_)


    var arrClients = Array.from(allClients.docs.map((e)=>{
			var objClient = e.data()
			objClient.id=e.id

			var inscriptions = objClient.inscriptions[actual_season_]

			var inscrTitles = ''
			if (inscriptions) {
				var inscrTitles = inscriptions.map(function (el) {
					return arrAbos.filter(abo => abo.id === el.id_abonnement)[0].title
				  });

				objClient.inscription = inscrTitles.toString()
				
			} else {
				objClient.inscription = []
			}
			return objClient
		}))

    // on prend les clients de cette saison
    var arrClientsSeason = arrClients.filter(client => client.inscriptions[`${actual_season_}`].length > 0)
    
    // on ajoute les cycles et les conférences à arrClientsSeason
    var arrClientsSeason = arrClientsSeason.map((client) => {
      //client.cycles = client.inscriptions[`${actual_season_}`].map(inscr => inscr.cycles)[0] // problème : on prend seulement le 1er cycle
      client.cycles = client.inscriptions[`${actual_season_}`].map(inscr => {
        if (inscr.cycles.length > 0) {
          return inscr.cycles[0]
        }
      }).filter(n => n)
      
      //client.conferences = client.inscriptions[`${actual_season_}`].map(inscr => inscr.conferences)[0] // problème : on prend seulement la 1ère conf
      client.conferences = client.inscriptions[`${actual_season_}`].map(inscr => {
        if (inscr.conferences.length > 0) {
          return inscr.conferences[0]
        }
        }).filter(n => n)
      
      if (client.cycles.length > 0) {
        client.cycleName = client.cycles.map((cycle) => {
          var cyclename_ = arrCycles.filter(cycle_ => cycle_.id === cycle)[0]?.title_cycle
          var cycle_number = arrCycles.filter(cycle_ => cycle_.id === cycle)[0]?.num_cycle
          var entity = arrCycles.filter(cycle_ => cycle_.id === cycle)[0]?.entity
          return `[${entity}] Cycle ${cycle_number} : ${cyclename_}`
        }).join()
      } else {
        client.cycleName = ''
      }

      if (client.conferences.length > 0) {
        client.confName = client.conferences.map((conference) => {
          var confname = arrConf.filter(conf_ => conf_.id === conference)[0].title
          var number_conf = arrConf.filter(conf_ => conf_.id === conference)[0].num_conference
          var entity = arrConf.filter(conf_ => conf_.id === conference)[0].entity
          var cycleId = arrConf.filter(conf_ => conf_.id === conference)[0].id_cycle
          var cycle_number = arrCycles.filter(cycle_ => cycle_.id === cycleId)[0]?.num_cycle
          return `[${entity}] Cycle ${cycle_number} - Conference ${number_conf} : ${confname}`
        }).join()
      } else {
        client.confName = ''
      }
      
      return client
    })
    

    const newArrClients = arrClients.map((client) => {
			if (client.inscriptions[`${actual_season_}`].length > 0) { // on prend seulement les clients abonnés cette année
				var objClient = Object()
				objClient.id = client.id
				objClient.firstname = client.firstname
				objClient.lastname = client.lastname
				objClient.abonnements = client.inscriptions[`${actual_season_}`].map(inscr => inscr.id_abonnement)
				objClient.aboName = objClient.abonnements.map((abo) => {
					var aboname = arrAbos.filter(abo_ => abo_.id === abo)[0].title
					return aboname
				}).join()
				objClient.cycles = client.inscriptions[`${actual_season_}`].map(inscr => inscr.cycles)[0]
				objClient.conferences = client.inscriptions[`${actual_season_}`].map(inscr => inscr.conferences)[0]

				if (objClient.conferences.length > 0) {
					objClient.confName = objClient.conferences.map((conference) => {
						var confname = arrConf.filter(conf_ => conf_.id === conference)[0].title
						return confname
					}).join()
				} else {
					objClient.confName = ''
				}
				
				return objClient
			}
			
		}).filter((client) => client != null) // on vire tous les users undefined

    var arrCycles = Array.from(allCycles.docs.map((e)=>{
			var objCycle = e.data()
			objCycle.id=e.id
      //objCycle.numberClients = nombre clients inscrits à ce cyle

      var clientsCycles = newArrClients.filter(cli => cli.cycles.includes(e.id))
      objCycle.numberClients = clientsCycles.length
      
			return objCycle
		})).filter(cycle => cycle.year === actual_season_)

    function sortFunction( a, b ) {
      if ( a.numberClients > b.numberClients ){
        return -1;
      }
      if ( a.numberClients < b.numberClients ){
        return 1;
      }
      return 0;
    }


    var arrAbos = Array.from(allAbonnements.docs.map((e)=>{
			var objAbo = e.data()
			objAbo.id = e.id

      var clientsAbos = newArrClients.filter(cli => cli.abonnements.includes(e.id))
      objAbo.numberClients = clientsAbos.length
			return objAbo
		}))

    arrCycles.sort(sortFunction)
    arrAbos.sort(sortFunction)

    var arrInvoices = Array.from(allInvoices.docs.map((e)=>{
			var objInvoice = e.data()
      var firstname = arrClients.filter(client => client.id === objInvoice.id_client)[0]?.firstname
      var lastname = arrClients.filter(client => client.id === objInvoice.id_client)[0]?.lastname
      objInvoice.client_name = `${firstname} ${lastname}`
      
      //objInvoice.a_paye = objInvoice.inscription.a_paye
      //var price = arrAbos.filter(abo => abo.id === objInvoice.inscription.id_abonnement)[0]?.price
      //var total = objInvoice.inscription.quantity*price
      //objInvoice.total = total
			return objInvoice
		}))

    var arrCredits = Array.from(allCredits.docs.map((e)=>{
			var objCredit = e.data()
      var firstname = arrClients.filter(client => client.id === objCredit.id_client)[0]?.firstname
      var lastname = arrClients.filter(client => client.id === objCredit.id_client)[0]?.lastname
      objCredit.client_name = `${firstname} ${lastname}`
      
      //objInvoice.a_paye = objInvoice.inscription.a_paye
      //var price = arrAbos.filter(abo => abo.id === objInvoice.inscription.id_abonnement)[0]?.price
      //var total = objInvoice.inscription.quantity*price
      //objInvoice.total = total
			return objCredit
		}))
    

    setAllYears(arrYears)
    setAllAbonnements(arrAbos)
    setAllClients(arrClients)
    setConferences(arrConf)
    setCycles(arrCycles)
    setInvoices(arrInvoices)

    const allData = {
      actual_season: actual_season_,
      clients: arrClients,
      clients_season: arrClientsSeason,
      years: arrYears,
      abonnements: arrAbos,
      invoices: arrInvoices,
      credits: arrCredits,
      conferences: arrConf,
      cycles: arrCycles,
      speakers: arrSpeakers
    }
    setData(allData)
  }
  
  useEffect(() => {
		mounted();
	  }, [])

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={()=> <Component_Dashboard data={data} setData={setData} />} />

        {/* Conférenciers */}
        <Route path={`${APP_PREFIX_PATH}/speakers`} component={()=> <Component_Speakers data={data} setData={setData} />} />

        <Route path={`${APP_PREFIX_PATH}/speakerId=:id_speaker/view`} component={()=> <Component_View_Speaker data={data} setData={setData} />} />

        <Route path={`${APP_PREFIX_PATH}/speakerId=:id_speaker/edit`} component={()=> <Component_Edit_Speaker data={data} setData={setData} />} />

        <Route path={`${APP_PREFIX_PATH}/addSpeaker`} component={()=> <Component_Add_Speaker data={data} setData={setData} />} />



        {/* Clients */}
        <Route path={`${APP_PREFIX_PATH}/nouveaux_auditeurs`} component={()=> <Component_New_Clients data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/contacts`} component={()=> <Component_Contacts data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/auditeurs`} component={()=> <Component_Clients data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/createUser`} component={()=> <Component_Add_Client data={data} setData={setData} />} />
        <Route path={`${APP_PREFIX_PATH}/userId=:id_user/view`} component={()=> <Component_View_Client data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/userId=:id_user/edit`} component={()=> <Component_Edit_Client data={data} setData={setData}/>} />


        {/* Cycles */}
        <Route path={`${APP_PREFIX_PATH}/cycles`} component={()=> <Component_Cycles data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/addCycle`} component={()=> <Component_Add_Cycle data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/cycleId=:id_cycle/edit`} component={()=> <Component_Edit_Cycle data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/cycleId=:id_cycle/view`} component={()=> <Component_View_Cycle data={data} setData={setData}/>} />


        {/* Conférences */} 
        <Route path={`${APP_PREFIX_PATH}/conferences`} component={()=> <Component_Confs data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/confId=:id_conf/view`} component={()=> <Component_View_Conf data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/confId=:id_conf/edit`} component={()=> <Component_Edit_Conf data={data} setData={setData}/>} />


        {/* Abonnements */}   
        <Route path={`${APP_PREFIX_PATH}/abonnements`} component={()=> <Component_Abonnements data={data} setData={setData}/>} />

        {/* Facture */}   
        <Route path={`${APP_PREFIX_PATH}/invoiceId=:id_invoice/view`} component={()=> <Component_View_Invoice data={data} setData={setData}/>} />

        <Route path={`${APP_PREFIX_PATH}/invoices`} component={()=> <Component_Invoices data={data} setData={setData}/>} />
        <Route path={`${APP_PREFIX_PATH}/credits`} component={()=> <Component_Credits data={data} setData={setData}/>} />

        <Route path={`${APP_PREFIX_PATH}/emailing`} component={()=> <Component_Emailing data={data} setData={setData}/>} />

        <Route path={`${APP_PREFIX_PATH}/accounting`} component={()=> <Component_Accounting data={data} setData={setData}/>} />

        
        <Route path={`${APP_PREFIX_PATH}/stats`} component={()=> <Component_Stats data={data} setData={setData}/>} />

        <Route path={`${APP_PREFIX_PATH}/stats_philo`} component={lazy(() => import(`./stats_philo`))} />
        <Route path={`${APP_PREFIX_PATH}/stats_litterature`} component={lazy(() => import(`./stats_litterature`))} />

        <Route path={`${APP_PREFIX_PATH}/settings`} component={()=> <Component_Settings data={data} setData={setData}/>} />
        
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);