import { 
  DashboardOutlined,
  MailOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  CalendarOutlined,
  LineChartOutlined,
  BarChartOutlined,
  AudioOutlined,
  ProfileOutlined,
  SettingOutlined,
  FolderOpenOutlined
} from '@ant-design/icons';
const APP_PREFIX_PATH = ''
const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}`,
  title: 'Menu',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboard',
      path: `${APP_PREFIX_PATH}/dashboard`,
      title: 'Tableau de bord',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'stats',
      path: `${APP_PREFIX_PATH}/stats`,
      title: 'Statistiques',
      icon: LineChartOutlined,
      breadcrumb: false,
      submenu: [{
        key: 'stats',
        path: `${APP_PREFIX_PATH}/stats`,
        title: 'Général',
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'stats_philo',
        path: `${APP_PREFIX_PATH}/stats_philo`,
        title: 'Philo',
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'stats_litterature',
        path: `${APP_PREFIX_PATH}/stats_litterature`,
        title: 'Littérature',
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: []
      }]
    },
    {
      key: 'contacts',
      path: `${APP_PREFIX_PATH}/contacts`,
      title: 'Tous les contacts',
      icon: TeamOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'auditeurs',
      path: `${APP_PREFIX_PATH}/auditeurs`,
      title: 'Auditeurs saison',
      icon: TeamOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'nouveaux_auditeurs',
      path: `${APP_PREFIX_PATH}/nouveaux_auditeurs`,
      title: 'Nouveaux auditeurs',
      icon: TeamOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'conferenciers',
      path: `${APP_PREFIX_PATH}/speakers`,
      title: 'Conférenciers',
      icon: AudioOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'cycles',
      path: `${APP_PREFIX_PATH}/cycles`,
      title: 'Cycles',
      icon: CalendarOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'conferences',
      path: `${APP_PREFIX_PATH}/conferences`,
      title: 'Toutes les conférences',
      icon: FolderOpenOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'accounting',
      path: `${APP_PREFIX_PATH}/accounting`,
      title: 'Comptabilité',
      icon: DollarCircleOutlined,
      breadcrumb: false,
      submenu: [{
        key: 'invoices',
        path: `${APP_PREFIX_PATH}/invoices`,
        title: 'Factures',
        icon: DollarCircleOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'credits',
        path: `${APP_PREFIX_PATH}/credits`,
        title: 'Avoirs',
        icon: DollarCircleOutlined,
        breadcrumb: false,
        submenu: []
      }]
    },
    {
      key: 'Emailing',
      path: `${APP_PREFIX_PATH}/emailing`,
      title: 'Emailing',
      icon: MailOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'abonnements',
      path: `${APP_PREFIX_PATH}/abonnements`,
      title: 'Abonnements',
      icon: ProfileOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'settings',
      path: `${APP_PREFIX_PATH}/settings`,
      title: 'Paramètres',
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    },
  ]
}
]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;  
