import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import { ConfigProvider } from 'antd';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';

const APP_PREFIX_PATH = '/'
export const Views = (props) => {
  const { location, direction } = props;
  useBodyClass(`dir-${direction}`);
  return (
      <ConfigProvider direction={direction}>
        <Switch>
          <Route exact path="/app">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route path={APP_PREFIX_PATH}>
            <AppLayout direction={direction} location={location} />
          </Route>
        </Switch>
      </ConfigProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { direction } =  theme;
  const { token } = auth;
  return { token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));