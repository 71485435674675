var FirebaseConfig = {
};

if (process.env.NODE_ENV == 'development') {
  
// lesmardis compte celim.starck@gmail.com
var FirebaseConfig = {
  apiKey: "AIzaSyDXTLeuQEkpBOi82d7HRCSIJmx2_ZRpD7w",
  authDomain: "app-mardis.firebaseapp.com",
  projectId: "app-mardis",
  storageBucket: "app-mardis.appspot.com",
  messagingSenderId: "387542214569",
  appId: "1:387542214569:web:75623fe5eab6e4410706b8"
};
  /* var FirebaseConfig = {
    apiKey: "AIzaSyCdPcRQck-32aaBf4DrSbGw46kBhm-Lyuw",
    authDomain: "lesmardis-dev.firebaseapp.com",
    projectId: "lesmardis-dev",
    storageBucket: "lesmardis-dev.appspot.com",
    messagingSenderId: "877269587894",
    appId: "1:877269587894:web:e65330071fdda5bf401317"
  }; */

var FirebaseConfig = {
    apiKey: "AIzaSyAY0HGwu58Dtxur99gnvvj7pkINgHyPl1o",
    authDomain: "lesmardis-e9d8a.firebaseapp.com",
    databaseURL: "https://lesmardis-e9d8a-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "lesmardis-e9d8a",
    storageBucket: "lesmardis-e9d8a.appspot.com",
    messagingSenderId: "387515473078",
    appId: "1:387515473078:web:896530107540db8e055a17"
  };
  // si on veut tester la base prod en dev, décommenter 
  /* var FirebaseConfig = {
    apiKey: "AIzaSyDXTLeuQEkpBOi82d7HRCSIJmx2_ZRpD7w",
    authDomain: "app-mardis.firebaseapp.com",
    projectId: "app-mardis",
    storageBucket: "app-mardis.appspot.com",
    messagingSenderId: "387542214569",
    appId: "1:387542214569:web:75623fe5eab6e4410706b8"
  }; */
    
} else {
  

  // lesmardis compte 
  var FirebaseConfig = {
    apiKey: "AIzaSyDXTLeuQEkpBOi82d7HRCSIJmx2_ZRpD7w",
    authDomain: "app-mardis.firebaseapp.com",
    projectId: "app-mardis",
    storageBucket: "app-mardis.appspot.com",
    messagingSenderId: "387542214569",
    appId: "1:387542214569:web:75623fe5eab6e4410706b8"
  };
}

export default FirebaseConfig

