import React, {useState, useEffect} from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import firebase from '@firebase/app';
const db = firebase.firestore();

const NAME_DB_YEARS = 'years'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const [tableauYears, setTableauYears] = useState([])
  const mounted = async () => {
    const allYears = await db.collection(NAME_DB_YEARS).get()
		
		var arrYears = Array.from(allYears.docs.map((e)=>{
			var objYear = e.data()
			return objYear
		}))	
    setTableauYears(arrYears)
  }
  
  useEffect(() => {
		mounted();
	  }, [])


  return (
      <div className="App">
        <Provider store={store}>
          <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
            <Router>
              <Switch>
                <Route path="/" component={Views} tableauYears={tableauYears} />
              </Switch>
            </Router>
          </ThemeSwitcherProvider>
        </Provider>
      </div>
  );
}

export default App;
