import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

import firebase from 'firebase/app';
//import 'firebase/firestore';
//const db = firebase.firestore();

/* const getYearSeason = async () => {
	const db = firebase.firestore();
} */

const test = "bonjour"

export const APP_NAME = 'Les Mardis';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const AUTH_PREFIX_PATH = '/auth';
//export const APP_YEAR_SEASON = getYearSeason()

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
